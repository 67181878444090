export default class TShirtCustomizer {
  constructor() {
    this.color = 'white';
    this.size = 'M';
    this.quantity = 1;
    this.imageFront = null;
    this.imageBack = null;
    this.compositeImageFront = null;
    this.compositeImageBack = null;
    this.imageXFront = 150;
    this.imageYFront = 150;
    this.imageWidthFront = 100;
    this.imageLengthFront = 100;
    this.imageXBack = 150;
    this.imageYBack = 150;
    this.imageWidthBack = 100;
    this.imageLengthBack = 100;
  }

  setColor(color) {
    this.color = color;
  }

  setSize(size) {
    this.size = size;
  }

  setQuantity(quantity) {
    this.quantity = quantity;
  }

  setImage(image, side) {
    if (side === 'front') {
      this.imageFront = image;
    } else if (side === 'back') {
      this.imageBack = image;
    }
  }

  setCompositeImage(compositeImage, side) {
    if (side === 'front') {
      this.compositeImageFront = compositeImage;
    } else if (side === 'back') {
      this.compositeImageBack = compositeImage;
    }
  }

  moveImage(x, y, side) {
    if (side === 'front') {
      this.imageXFront = x;
      this.imageYFront = y;
    } else if (side === 'back') {
      this.imageXBack = x;
      this.imageYBack = y;
    }
  }

  resizeImage(width, length, side) {
    if (side === 'front') {
      this.imageWidthFront = Math.max(20, Math.min(300, width));
      this.imageLengthFront = Math.max(20, Math.min(300, length));
    } else if (side === 'back') {
      this.imageWidthBack = Math.max(20, Math.min(300, width));
      this.imageLengthBack = Math.max(20, Math.min(300, length));
    }
  }

  getDescription() {
    let sizeWord;
    if (this.size === 'S') {
      sizeWord = 'small';
    } else if (this.size === 'M') {
      sizeWord = 'medium';
    } else if (this.size === 'L') {
      sizeWord = 'large';
    } else {
      sizeWord = 'unknown';
    }

    let imageDescription = '';
    if (this.imageFront && this.imageBack) {
      imageDescription = ' with custom images on front and back';
    } else if (this.imageFront) {
      imageDescription = ' with custom image on front';
    } else if (this.imageBack) {
      imageDescription = ' with custom image on back';
    }

    return `${this.quantity} ${sizeWord} ${this.color} t-shirt${this.quantity > 1 ? 's' : ''}${imageDescription}.`;
  }

  getTotalPrice() {
    let basePrice = 15;
    let sizePrice = this.size === 'S' ? 5 : this.size === 'M' ? 10 : this.size === 'L' ? 15 : 0;
    let imagePrice = 0;
    if (this.imageFront) imagePrice += 5;
    if (this.imageBack) imagePrice += 5;
    return (basePrice + sizePrice + imagePrice) * this.quantity;
  }

  clone() {
    const clone = new TShirtCustomizer();
    Object.assign(clone, this);
    return clone;
  }
}

