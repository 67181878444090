import React, { useState, useEffect, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, AddressElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, Typography } from '@mui/material';
import config from '../config';

const SERVER_URL = config.REACT_APP_SERVER_URL;

// Load Stripe outside of the component to avoid recreating the Stripe object
const stripePromise = loadStripe('pk_test_51PijfvJrJRZWjXkA0l9fNFdRq7jPQPo3m3yHH3AmJwlZ519k2mr5y3ONFilqqs0GjJFr8u6YtQ3zgD4DEOxtC5Fi00TYc3QjIT');
const productId = "prod_QaNXA9BuSZWna4";

const PaymentForm = ({ cart, customerInfo, onPaymentSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);

        if (!stripe || !elements) {
            setProcessing(false);
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            setError(submitError.message);
            setProcessing(false);
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/payment-confirmation`,
            },
            redirect: 'if_required'
        });

        if (result.error) {
            setError(result.error.message);
            setProcessing(false);
        } else {
            if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
                const paymentId = result.paymentIntent.id;
                onPaymentSuccess(cart, customerInfo, paymentId);
                console.log('Payment succeeded! Payment ID:', paymentId);
                setPaymentSuccess(true);
            } else {
                setError('Payment processing failed. Please try again.');
            }
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box mb={2}>
                <AddressElement options={{mode: 'shipping'}} />
            </Box>
            <PaymentElement />
            <Box mt={2}>
                {error && <Typography color="error">{error}</Typography>}
                {paymentSuccess ? (
                    <Typography color="success">✅ Payment Successful!</Typography>
                ) : (
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary"
                        disabled={processing || !stripe}
                    >
                        {processing ? 'Processing...' : 'Pay and Submit Order'}
                    </Button>
                )}
            </Box>
        </form>
    );
};

const StripePaymentForm = ({ cart, customerInfo, onPaymentSuccess }) => {
    const [clientSecret, setClientSecret] = useState('');
    const paymentIntentCreated = useRef(false);

    useEffect(() => {
        if (!paymentIntentCreated.current) {
            paymentIntentCreated.current = true;

            console.log("email:", customerInfo.email);
            
            fetch(`${SERVER_URL}/create-payment-intent`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    items: cart.map(item => ({
                        productId: productId,
                        size: item.size,
                        quantity: item.quantity
                    })),
                    email: customerInfo.email  // Add the customer's email here
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.error) {
                    console.error(data.error);
                } else {
                    console.log("create-payment-intent response:", data);
                    setClientSecret(data.clientSecret);
                }
            })
            .catch(err => {
                console.error('Error:', err);
            });
        }
    }, [cart, customerInfo.email]);  // Add customerInfo.email to the dependency array

    const options = {
        clientSecret,
        appearance: { theme: 'stripe' },
    };

    return (
        <Box>
            {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <PaymentForm cart={cart} customerInfo={customerInfo} onPaymentSuccess={onPaymentSuccess} />
                </Elements>
            )}
        </Box>
    );
};

export default StripePaymentForm;