import React, { useState, useEffect, useRef } from 'react';
import { Box, SvgIcon, Tooltip } from '@mui/material';
import { drawTShirt } from '../utils/drawTShirt';

// Custom resize icon
const ResizeIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M22 22H20V20H22V22ZM22 20H20V18H22V20ZM20 22H18V20H20V22ZM20 20H18V18H20V20ZM22 18H20V16H22V18Z" />
  </SvgIcon>
);

const TShirt = ({
  color,
  uploadedImage,
  onCompositeImageCreated,
  onImageBoundsChange,
  handleUpdateImageSize,
  side,
}) => {
  const [error, setError] = useState(null);
  const [imagePositions, setImagePositions] = useState({
    front: { x: 0, y: 0 },
    back: { x: 0, y: 0 },
  });
  const [imageSizes, setImageSizes] = useState({
    front: { width: 200, height: 200 },
    back: { width: 200, height: 200 },
  });
  const [aspectRatios, setAspectRatios] = useState({
    front: 1,
    back: 1,
  });
  const [isDraggings, setIsDraggings] = useState({
    front: false,
    back: false,
  });
  const [isResizings, setIsResizings] = useState({
    front: false,
    back: false,
  });
  const [dragStarts, setDragStarts] = useState({
    front: { x: 0, y: 0 },
    back: { x: 0, y: 0 },
  });
  const [isImageHovereds, setIsImageHovereds] = useState({
    front: false,
    back: false,
  });

  const [prevUploadedImages, setPrevUploadedImages] = useState({
    front: null,
    back: null,
  });

  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const tshirtRef = useRef(null);
  const canvasSize = 600;
  const gridSize = canvasSize / 3;

  const [isImageLoadeds, setIsImageLoadeds] = useState({
    front: false,
    back: false,
  });

  const imagePosition = imagePositions[side];
  const imageSize = imageSizes[side];
  const aspectRatio = aspectRatios[side];
  const isDragging = isDraggings[side];
  const isResizing = isResizings[side];
  const dragStart = dragStarts[side];
  const isImageHovered = isImageHovereds[side];
  const isImageLoaded = isImageLoadeds[side];
  const prevUploadedImage = prevUploadedImages[side];

  useEffect(() => {
    if (uploadedImage && uploadedImage !== prevUploadedImage) {
      setPrevUploadedImages((prev) => ({ ...prev, [side]: uploadedImage }));
      const img = new Image();
      img.onload = () => {
        const naturalAspectRatio = img.naturalWidth / img.naturalHeight;
        setAspectRatios((prev) => ({ ...prev, [side]: naturalAspectRatio }));

        const maxDimension = gridSize;
        let newWidth, newHeight;

        if (naturalAspectRatio > 1) {
          // Landscape image
          newWidth = maxDimension;
          newHeight = maxDimension / naturalAspectRatio;
        } else {
          // Portrait image or square
          newHeight = maxDimension;
          newWidth = maxDimension * naturalAspectRatio;
        }

        setImageSizes((prev) => ({
          ...prev,
          [side]: { width: newWidth, height: newHeight },
        }));
        setImagePositions((prev) => ({
          ...prev,
          [side]: {
            x: (gridSize - newWidth) / 2,
            y: (gridSize - newHeight) / 2,
          },
        }));
        handleUpdateImageSize(newWidth, newHeight, side);
        setIsImageLoadeds((prev) => ({ ...prev, [side]: true }));
      };
      img.src = uploadedImage;
    } else if (!uploadedImage && prevUploadedImage) {
      // Uploaded image has been removed for this side
      setPrevUploadedImages((prev) => ({ ...prev, [side]: null }));
      setIsImageLoadeds((prev) => ({ ...prev, [side]: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImage]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const { textureImage, maskImage } = drawTShirt(
        canvas,
        color,
        uploadedImage,
        imageRef,
        imagePosition,
        imageSize,
        isDragging,
        isResizing,
        onCompositeImageCreated,
        side // Pass the side to drawTShirt
      );

      textureImage.onerror = function () {
        setError(
          "Failed to load the texture image. Please check if 'tshirt_texture.png' exists in the public folder."
        );
      };

      maskImage.onerror = function () {
        setError(
          "Failed to load the mask image. Please check if 'tshirt_mask.png' exists in the public folder."
        );
      };
    }
  }, [
    color,
    uploadedImage,
    imagePosition,
    imageSize,
    isDragging,
    isResizing,
    onCompositeImageCreated,
    side,
    imageRef,
  ]);

  const handleMouseDown = (e) => {
    if (uploadedImage && tshirtRef.current) {
      setIsDraggings((prev) => ({ ...prev, [side]: true }));
      const rect = tshirtRef.current.getBoundingClientRect();
      setDragStarts((prev) => ({
        ...prev,
        [side]: {
          x: e.clientX - rect.left - imagePosition.x,
          y: e.clientY - rect.top - imagePosition.y,
        },
      }));
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && tshirtRef.current) {
      const rect = tshirtRef.current.getBoundingClientRect();
      const newX = e.clientX - rect.left - dragStart.x;
      const newY = e.clientY - rect.top - dragStart.y;

      // Calculate the maximum offsets based on the image dimensions
      const maxOffsetX = (gridSize - imageSize.width) / 2;
      const maxOffsetY = (gridSize - imageSize.height) / 2;

      // Limit the movement within the design area
      const limitedX = Math.max(Math.min(newX, maxOffsetX), -maxOffsetX);
      const limitedY = Math.max(Math.min(newY, maxOffsetY), -maxOffsetY);

      setImagePositions((prev) => ({
        ...prev,
        [side]: { x: limitedX, y: limitedY },
      }));
    } else if (isResizing && tshirtRef.current) {
      const rect = tshirtRef.current.getBoundingClientRect();
      const newWidth = e.clientX - rect.left - gridSize - imagePosition.x;
      const newHeight = newWidth / aspectRatio;

      setImageSizeWithAspectRatio(newWidth, newHeight);
    }
  };

  const handleMouseUp = () => {
    setIsDraggings((prev) => ({ ...prev, [side]: false }));
    setIsResizings((prev) => ({ ...prev, [side]: false }));
    checkImageBounds();
  };

  const handleImageResize = (e) => {
    setIsResizings((prev) => ({ ...prev, [side]: true }));
    e.stopPropagation();
  };

  const checkImageBounds = () => {
    if (!uploadedImage) return;

    const centerX = gridSize + (gridSize - imageSize.width) / 2;
    const centerY = gridSize + (gridSize - imageSize.height) / 2;

    const imageLeft = centerX + imagePosition.x;
    const imageRight = imageLeft + imageSize.width;
    const imageTop = centerY + imagePosition.y;
    const imageBottom = imageTop + imageSize.height;

    // Use a small tolerance value (e.g., 0.1 pixels) to allow images right on the edge
    const tolerance = 5;
    const isWithinBounds =
      imageLeft >= gridSize - tolerance &&
      imageRight <= gridSize * 2 + tolerance &&
      imageTop >= gridSize - tolerance &&
      imageBottom <= gridSize * 2 + tolerance;

    onImageBoundsChange(isWithinBounds, side);
  };

  useEffect(() => {
    checkImageBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePosition, imageSize]);

  const setImageSizeWithAspectRatio = (newWidth, newHeight) => {
    const maxSize = gridSize;
    let limitedWidth, limitedHeight;

    if (aspectRatio > 1) {
      // Landscape
      limitedWidth = Math.min(newWidth, maxSize);
      limitedHeight = limitedWidth / aspectRatio;
    } else {
      // Portrait or square
      limitedHeight = Math.min(newHeight, maxSize);
      limitedWidth = limitedHeight * aspectRatio;
    }

    // Ensure minimum size
    limitedWidth = Math.max(limitedWidth, 50);
    limitedHeight = Math.max(limitedHeight, 50);

    setImageSizes((prev) => ({
      ...prev,
      [side]: { width: limitedWidth, height: limitedHeight },
    }));
    handleUpdateImageSize(limitedWidth, limitedHeight, side);
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <Box
      ref={tshirtRef}
      sx={{ position: 'relative', width: `${canvasSize}px`, height: `${canvasSize}px` }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <canvas ref={canvasRef} width={canvasSize} height={canvasSize} />
      {uploadedImage && isImageLoaded && (
        <>
          <Box
            onMouseEnter={() =>
              setIsImageHovereds((prev) => ({ ...prev, [side]: true }))
            }
            onMouseLeave={() =>
              setIsImageHovereds((prev) => ({ ...prev, [side]: false }))
            }
            sx={{
              position: 'absolute',
              left: `${gridSize + (gridSize - imageSize.width) / 2 + imagePosition.x}px`,
              top: `${gridSize + (gridSize - imageSize.height) / 2 + imagePosition.y}px`,
              width: `${imageSize.width}px`,
              height: `${imageSize.height}px`,
              cursor: 'move',
            }}
          >
            <img
              ref={imageRef}
              src={uploadedImage}
              alt="Uploaded design"
              style={{
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
              }}
            />
          </Box>
          <Tooltip title="Click and drag to resize the image" placement="top" arrow>
            <Box
              sx={{
                position: 'absolute',
                right: `${canvasSize - (gridSize + (gridSize - imageSize.width) / 2 + imagePosition.x + imageSize.width)}px`,
                bottom: `${canvasSize - (gridSize + (gridSize - imageSize.height) / 2 + imagePosition.y + imageSize.height)}px`,
                width: '24px',
                height: '24px',
                cursor: 'nwse-resize',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                borderRadius: '4px',
                boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)',
                opacity: isImageHovered ? 1 : 0,
                transition: 'opacity 0.3s ease-in-out',
                zIndex: 10,
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                handleImageResize(e);
              }}
            >
              <ResizeIcon sx={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.7)' }} />
            </Box>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default TShirt;

