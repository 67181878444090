import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Typography, Paper, Box, Divider, Tooltip, Chip, Button } from '@mui/material';
import { fetchOrderById, getSignedUrl } from '../utils/orders';
import { ShoppingBag, Calendar, CreditCard, Mail, Copy, Check, Search, Home } from 'lucide-react';

const OrderDetailsPage = () => {
  const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const loadOrderData = async () => {
      try {
        const data = await fetchOrderById(orderId, key);
        if (!data) {
          setError('Order not found');
          setLoading(false);
          return;
        }
        const updatedItems = await Promise.all(
          data.items.map(async (item) => {
            const compositeImages = item.images.filter(image => image.type === 'composite');
            const updatedCompositeImages = await Promise.all(
              compositeImages.map(async (image) => {
                const objectKey = image.imageUrl.split('/').pop();
                const signedUrl = await getSignedUrl(objectKey);
                return { ...image, signedImageUrl: signedUrl };
              })
            );
            return { ...item, compositeImages: updatedCompositeImages };
          })
        );
        setOrderData({ ...data, items: updatedItems });
        setLoading(false);
      } catch (err) {
        console.error('Error loading order data:', err);
        setError('Failed to load order data');
        setLoading(false);
      }
    };
    loadOrderData();
  }, [orderId, key]);

  const handleCopyOrderId = () => {
    navigator.clipboard.writeText(orderData.orderId).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatPrice = (price, currency) => {
    return `$${parseFloat(price).toFixed(2)} CAD`;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending': return 'info';
      case 'In Progress': return 'info';
      case 'Shipped': return 'primary';
      case 'Delivered': return 'success';
      case 'Cancelled': return 'error';
      default: return 'default';
    }
  };

  const getFullSizeName = (sizeCode) => {
    const sizeMap = {
      'XS': 'extra small',
      'S': 'small',
      'M': 'medium',
      'L': 'large',
      'XL': 'extra large',
      '2XL': '2x large',
      '3XL': '3x large',
      '4XL': '4x large',
      '5XL': '5x large'
    };
    return sizeMap[sizeCode] || sizeCode.toLowerCase();
  };

  if (loading) return <Typography>Loading...</Typography>;
  
  if (error) {
    return (
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4, textAlign: 'center' }}>
          <Search size={64} color="#3f51b5" />
          <Typography variant="h5" sx={{ mt: 2, mb: 2, color: '#3f51b5' }}>
            Order not found
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate('/')}
            startIcon={<ShoppingBag />}
            sx={{ 
              borderRadius: 28,
              textTransform: 'none',
              fontSize: '1rem',
              px: 3,
              py: 1
            }}
          >
            Back Home
          </Button>
        </Paper>
      </Container>
    );
  }

  if (!orderData) return null;

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
            Order Details
          </Typography>
          <Chip
            label={orderData.status}
            color={getStatusColor(orderData.status)}
            sx={{ fontWeight: 'bold', fontSize: '1rem' }}
          />
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ShoppingBag size={24} color="#1976d2" />
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography sx={{ mr: 1 }}>Order ID:</Typography>
              <Tooltip title={copied ? "Copied!" : "Click to copy Order ID"}>
                <Box 
                  onClick={handleCopyOrderId} 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    cursor: 'pointer', 
                    '&:hover': { bgcolor: 'rgba(25, 118, 210, 0.04)' },
                    borderRadius: 1,
                    p: 0.5
                  }}
                >
                  <Typography sx={{ mr: 1, fontWeight: 'bold' }}>
                    {orderData.orderId}
                  </Typography>
                  {copied ? <Check size={18} color="#4caf50" /> : <Copy size={18} color="#1976d2" />}
                </Box>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Calendar size={24} color="#1976d2" />
            <Typography sx={{ ml: 2 }}>
              Order Date: {formatDate(orderData.orderDate)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CreditCard size={24} color="#1976d2" />
            <Typography sx={{ ml: 2 }}>
              Total Price: {formatPrice(orderData.totalPrice, orderData.currency)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Mail size={24} color="#1976d2" />
            <Typography sx={{ ml: 2 }}>
              Email: {orderData.email}
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
            Order Items
          </Typography>
          {orderData.items.map((item, index) => (
            <Paper key={item.itemId} elevation={2} sx={{ p: 3, mb: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                <Box sx={{ 
                  width: { xs: '100%', md: '50%' }, 
                  display: 'flex', 
                  flexDirection: 'row', 
                  justifyContent: 'center', 
                  gap: 2 
                }}>
                  {item.compositeImages.map((image, imgIndex) => (
                    <Box key={imgIndex} sx={{ width: '50%', textAlign: 'center' }}>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block' }}>
                        {image.position.charAt(0).toUpperCase() + image.position.slice(1)}
                      </Typography>
                      <img
                        src={image.signedImageUrl}
                        alt={`T-Shirt ${index + 1} ${image.position}`}
                        style={{
                          width: '100%',
                          maxWidth: 150,
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  ))}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography><strong>Size:</strong> {getFullSizeName(item.size)}</Typography>
                  <Typography><strong>Color:</strong> {item.color}</Typography>
                  <Typography><strong>Quantity:</strong> {item.quantity}</Typography>
                  <Typography><strong>Price:</strong> {formatPrice(item.price, item.currency)}</Typography>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate('/')}
            startIcon={<Home />}
            sx={{ 
              borderRadius: 28,
              textTransform: 'none',
              fontSize: '1rem',
              px: 3,
              py: 1
            }}
          >
            Back Home
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default OrderDetailsPage;
