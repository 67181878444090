import React, { useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  Grid,
  IconButton,
  Paper,
  Divider,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import ImageModal from './ImageModal';
import QuantityControl from './QuantityControl';

const OrderSummary = ({ cart, customerInfo, updateQuantity, removeItem, mutable }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageClick = (images) => {
    setSelectedImages(images);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const totalPrice = cart.reduce((sum, item) => sum + item.getTotalPrice(), 0);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Order Summary
      </Typography>
      <List disablePadding>
        {cart.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem disableGutters sx={{ py: 1 }}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleImageClick([item.compositeImageFront, item.compositeImageBack])
                    }
                  >
                    {item.compositeImageFront && (
                      <Box
                        component="img"
                        src={item.compositeImageFront}
                        alt={`${item.size} ${item.color} shirt Front`}
                        sx={{
                          width: '50%',
                          height: 'auto',
                        }}
                      />
                    )}
                    {item.compositeImageBack && (
                      <Box
                        component="img"
                        src={item.compositeImageBack}
                        alt={`${item.size} ${item.color} shirt Back`}
                        sx={{
                          width: '50%',
                          height: 'auto',
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    {`${item.size} ${item.color} shirt`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ${item.getTotalPrice() ? item.getTotalPrice().toFixed(2) : '0.00'}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                  >
                    {mutable ? (
                      <>
                        <QuantityControl
                          value={item.quantity}
                          onIncrease={() => updateQuantity(index, item.quantity + 1)}
                          onDecrease={() => updateQuantity(index, item.quantity - 1)}
                          min={1}
                        />
                        <IconButton
                          size="small"
                          aria-label="delete"
                          onClick={() => removeItem(index)}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </>
                    ) : (
                      <Typography variant="body2">Qty: {item.quantity}</Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </ListItem>
            {index < cart.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      <Divider sx={{ my: 1 }} />
      <Typography variant="h6" align="right">
        Total: ${totalPrice.toFixed(2)}
      </Typography>
      <ImageModal
        open={modalOpen}
        handleClose={handleCloseModal}
        imageSrcs={selectedImages}
      />
    </Paper>
  );
};

export default OrderSummary;

