import React from 'react';
import { Modal, Box, Typography } from '@mui/material';

const ImageModal = ({ open, handleClose, imageSrcs }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    maxWidth: '90vw',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
  };

  const validImageSrcs = imageSrcs ? imageSrcs.filter(src => src != null) : [];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {validImageSrcs.length > 0 ? (
          validImageSrcs.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`T-Shirt Design ${index === 0 ? 'Front' : 'Back'}`}
              style={{
                maxWidth: '50%',
                maxHeight: '100%',
                objectFit: 'contain',
                margin: '0 10px',
              }}
            />
          ))
        ) : (
          <Typography variant="body1">No images available</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ImageModal;
