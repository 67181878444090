import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

function CustomerInfoStep({ customerInfo, onCustomerInfoChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onCustomerInfoChange({ ...customerInfo, [name]: value });
    };

    return (
        <Box>
            <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={customerInfo.email}
                onChange={handleInputChange}
                margin="normal"
            />
            <Typography variant="body2" color="textSecondary" mt={1}>
                Your email will be used to send you an order confirmation and to allow you to view your order details.
            </Typography>
        </Box>
    );
}

export default CustomerInfoStep;