export const drawRulers = (ctx, isResizing, gridSize, imageSize, imagePosition) => {
  if (isResizing) {
    ctx.save();
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
    ctx.font = 'bold 16px Arial';
    ctx.lineWidth = 1;

    const imageLeft = gridSize + (gridSize - imageSize.width) / 2 + imagePosition.x;
    const imageTop = gridSize + (gridSize - imageSize.height) / 2 + imagePosition.y;
    const pixelsPerInch = gridSize / 12;

    // Draw boundary rulers (12" x 12")
    ctx.beginPath();
    ctx.moveTo(gridSize, gridSize);
    ctx.lineTo(gridSize * 2, gridSize);
    ctx.moveTo(gridSize, gridSize);
    ctx.lineTo(gridSize, gridSize * 2);
    ctx.stroke();

    // Draw boundary measurements
    drawTextWithBackground(ctx, '12"', gridSize + gridSize / 2, gridSize - 5);
    drawTextWithBackground(ctx, '12"', gridSize - 5, gridSize + gridSize / 2, true);

    // Draw image rulers
    // Bottom ruler
    const bottomRulerY = imageTop + imageSize.height;
    ctx.beginPath();
    ctx.moveTo(imageLeft, bottomRulerY);
    ctx.lineTo(imageLeft + imageSize.width, bottomRulerY);
    ctx.stroke();

    // Right ruler
    const rightRulerX = imageLeft + imageSize.width;
    ctx.beginPath();
    ctx.moveTo(rightRulerX, imageTop);
    ctx.lineTo(rightRulerX, imageTop + imageSize.height);
    ctx.stroke();

    // Draw image measurements
    const widthInches = (imageSize.width / pixelsPerInch).toFixed(2);
    const heightInches = (imageSize.height / pixelsPerInch).toFixed(2);

    drawTextWithBackground(ctx, `${widthInches}"`, imageLeft + imageSize.width / 2, bottomRulerY + 5);
    drawTextWithBackground(ctx, `${heightInches}"`, rightRulerX + 5, imageTop + imageSize.height / 2, true);
    
    ctx.restore();
  }
};

const drawTextWithBackground = (ctx, text, x, y, rotate = false) => {
  const padding = 2;
  const textWidth = ctx.measureText(text).width;
  const textHeight = 14; // Approximate height of the text

  ctx.save();
  if (rotate) {
    ctx.translate(x, y);
    ctx.rotate(-Math.PI / 2);
    x = -textWidth / 2;
    y = textHeight / 2;
  } else {
    x -= textWidth / 2;
    y += textHeight / 2;
  }

  // Draw background
  ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
  ctx.fillRect(x - padding, y - textHeight - padding, textWidth + padding * 2, textHeight + padding * 2);

  // Draw text
  ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
  ctx.fillText(text, x, y);
  ctx.restore();
};
