import React, { useState } from 'react';
import { CheckCircle } from 'lucide-react';
import StripePaymentForm from './StripePaymentForm';

function PaymentStep({ cart, customerInfo, onPaymentSuccess }) {
  const [paymentComplete, setPaymentComplete] = useState(false);

  const handlePaymentSuccess = (cart, customerInfo, paymentId) => {
    setPaymentComplete(true);
    onPaymentSuccess(paymentId);
  };

  if (paymentComplete) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full space-y-6 transition-all duration-500 ease-in-out transform hover:scale-105">
          <div className="text-center">
            <div className="mx-auto bg-green-100 rounded-full p-3 w-16 h-16 flex items-center justify-center mb-4">
              <CheckCircle className="w-10 h-10 text-green-500" />
            </div>
            <h2 className="text-3xl font-bold text-gray-800 mb-2">Payment Successful!</h2>
            <p className="text-gray-600 mb-4">
              Thank you for your purchase. We've sent a confirmation email to:
            </p>
            <div className="bg-gray-100 rounded-md p-3 mb-4">
              <p className="font-medium text-gray-800">{customerInfo.email}</p>
            </div>
            <p className="text-sm text-gray-500">
              Please check your email for further details about your order.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
        <StripePaymentForm
          cart={cart}
          customerInfo={customerInfo}
          onPaymentSuccess={handlePaymentSuccess}
        />
      </div>
    </div>
  );
}

export default PaymentStep;