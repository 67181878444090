import { readAndCompressImage } from 'browser-image-resizer';
import config from '../config';

const SERVER_URL = config.REACT_APP_SERVER_URL;

async function resizeImage(base64Data, targetWidthInches, targetHeightInches, dpi = 300) {
    // Extract the MIME type and base64 content
    const matches = base64Data.match(/^data:(.+);base64,(.+)$/);
    if (!matches || matches.length !== 3) {
        throw new Error('Invalid base64 data format');
    }

    const mimeType = matches[1];
    const base64Content = matches[2];

    // Convert base64 to Blob
    const byteCharacters = atob(base64Content);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: mimeType });

    // Calculate target dimensions in pixels
    const targetWidth = Math.round(targetWidthInches * dpi);
    const targetHeight = Math.round(targetHeightInches * dpi);

    // Resize the image
    const config = {
        quality: 0.7,
        width: targetWidth,
        height: targetHeight,
        mimeType: mimeType
    };

    const resizedFile = await readAndCompressImage(blob, config);

    // Convert the resized file to base64
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(resizedFile);
    });
}

function uploadImage(base64Data) {
    return new Promise((resolve, reject) => {
        if (!base64Data || typeof base64Data !== 'string') {
            reject(new Error('Invalid base64 data provided'));
            return;
        }

        // Extract the MIME type and base64 content
        const matches = base64Data.match(/^data:(.+);base64,(.+)$/);
        if (!matches || matches.length !== 3) {
            reject(new Error('Invalid base64 data format'));
            return;
        }

        const mimeType = matches[1];
        const base64Content = matches[2];

        // Convert base64 to blob
        const byteCharacters = atob(base64Content);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: mimeType });

        // Create form data
        const formData = new FormData();
        formData.append('image', blob, `image.${mimeType.split('/')[1]}`);

        // Send the request
        fetch(`${SERVER_URL}/upload`, {
            method: 'POST',
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            resolve(data.fileUrl);
        })
        .catch((error) => {
            console.error('Error:', error);
            reject(error);
        });
    });
}

export async function submitOrder(cart, customerInfo, paymentId) {
    const TODO = 'TODO';
    const DPI = 300;

    try {
        // Process images for each item in the cart
        const processPromises = cart.map(async (item) => {
            const imagesArray = [];

            // Process front composite image
            if (item.compositeImageFront) {
                const frontCompositeImageUrl = await uploadImage(item.compositeImageFront);
                imagesArray.push({
                    position: 'front',
                    type: 'composite',
                    imageUrl: frontCompositeImageUrl
                });
            }

            // Process back composite image
            if (item.compositeImageBack) {
                const backCompositeImageUrl = await uploadImage(item.compositeImageBack);
                imagesArray.push({
                    position: 'back',
                    type: 'composite',
                    imageUrl: backCompositeImageUrl
                });
            }

            // Process front resized image
            if (item.imageFront) {
                const imageWidthInches = item.imageWidthFront * (12 / 200);
                const imageLengthInches = item.imageLengthFront * (12 / 200);

                // Resize the image
                const resizedBase64Image = await resizeImage(
                    item.imageFront,
                    imageWidthInches,
                    imageLengthInches,
                    DPI
                );

                // Upload the resized image
                const imageUrl = await uploadImage(resizedBase64Image);

                imagesArray.push({
                    position: 'front',
                    type: 'resized',
                    imageUrl: imageUrl,
                    imageWidthInches: imageWidthInches,
                    imageLengthInches: imageLengthInches
                });
            }

            // Process back resized image
            if (item.imageBack) {
                const imageWidthInches = item.imageWidthBack * (12 / 200);
                const imageLengthInches = item.imageLengthBack * (12 / 200);

                // Resize the image
                const resizedBase64Image = await resizeImage(
                    item.imageBack,
                    imageWidthInches,
                    imageLengthInches,
                    DPI
                );

                // Upload the resized image
                const imageUrl = await uploadImage(resizedBase64Image);

                imagesArray.push({
                    position: 'back',
                    type: 'resized',
                    imageUrl: imageUrl,
                    imageWidthInches: imageWidthInches,
                    imageLengthInches: imageLengthInches
                });
            }

            // Assign the processed images to the item
            item.processedImages = imagesArray;
        });

        // Wait for all image processing to complete
        await Promise.all(processPromises);

        const email = customerInfo.email;

        const order = {
            email: email,
            shippingAddress: TODO, // cannot replace this yet
            items: cart.map(item => ({
                productId: TODO,
                size: item.size,
                color: item.color,
                quantity: item.quantity,
                price: item.getTotalPrice(),
                currency: TODO,
                images: JSON.stringify(item.processedImages)
            })),
            totalPrice: cart.reduce((sum, item) => sum + item.getTotalPrice(), 0),
            currency: TODO,
            stripePaymentId: paymentId
        };

        console.log('Submitting order:', order);

        const response = await fetch(`${SERVER_URL}/orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
        });

        if (!response.ok) {
            throw new Error('Failed to submit order');
        }

        const result = await response.json();
        console.log('Order submitted successfully:', result);
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export const fetchOrderById = async (orderId, key) => {
  try {
    const url = new URL(`${SERVER_URL}/orders/${orderId}`);
    url.searchParams.append('key', key);

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch order data');
    }
    const orderData = await response.json();
    return orderData;
  } catch (error) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const getSignedUrl = async (objectKey) => {
    try {
      const response = await fetch(`${SERVER_URL}/get-signed-url?objectKey=${objectKey}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      return data.signedUrl;
    } catch (error) {
      console.error('Error fetching signed URL:', error);
      return null;
    }
  };
