import React from 'react';
import { Minus, Plus } from 'lucide-react';

const QuantityControl = ({ value = 2, onIncrease, onDecrease, min = 0, max = Infinity }) => {
  return (
    <div className="flex items-center bg-white rounded-md shadow-sm">
      <button
        onClick={onDecrease}
        disabled={value <= min}
        className="p-2 text-gray-500 hover:text-gray-700 disabled:opacity-50"
      >
        <Minus size={16} />
      </button>
      <span className="mx-4 min-w-[24px] text-center">{value}</span>
      <button
        onClick={onIncrease}
        disabled={value >= max}
        className="p-2 text-gray-500 hover:text-gray-700 disabled:opacity-50"
      >
        <Plus size={16} />
      </button>
    </div>
  );
};

export default QuantityControl;