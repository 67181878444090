import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
} from '@mui/material';

import CustomizationStep from './components/CustomizationStep';
import CustomerInfoStep from './components/CustomerInfoStep';
import PaymentStep from './components/PaymentStep';
import OrderSummary from './components/OrderSummary';
import TShirtCustomizer from './TShirtCustomizer';
import OrderDetailsPage from './components/OrderDetailsPage';
import { submitOrder } from './utils/orders';

function App() {
  const [activeStep, setActiveStep] = useState(0);
  const [cart, setCart] = useState([]);
  const [customizer, setCustomizer] = useState(new TShirtCustomizer());
  const [customerInfo, setCustomerInfo] = useState({ email: '' });
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [emailError, setEmailError] = useState('');
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      // drawTShirt(ctx, customizer);
    }
  }, [customizer]);

  const handleNext = () => {
    if (activeStep === 1) {
      if (!validateEmail(customerInfo.email)) {
        setEmailError('Please enter a valid email address');
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddToCart = () => {
    setCart([...cart, customizer.clone()]);
    setCustomizer(new TShirtCustomizer());
  };

  const handlePaymentSuccess = (paymentId) => {
    submitOrder(cart, customerInfo, paymentId);
    setPaymentSuccessful(true);
  };

  const handleCustomerInfoChange = (info) => {
    setCustomerInfo(info);
    if (info.email) {
      setEmailError('');
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleUpdateQuantity = (index, newQuantity) => {
    if (newQuantity > 0) {
      setCart((prevCart) => {
        const newCart = [...prevCart];
        newCart[index].quantity = newQuantity;
        return newCart;
      });
    }
  };

  const cartMutable = activeStep === 0;

  const handleRemoveItem = (index) => {
    setCart((prevCart) => prevCart.filter((_, i) => i !== index));
  };

  const steps = ['Customize your T-Shirt', 'Customer Information', 'Payment'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CustomizationStep
            customizer={customizer}
            setCustomizer={setCustomizer}
            canvasRef={canvasRef}
            handleAddToCart={handleAddToCart}
          />
        );
      case 1:
        return (
          <CustomerInfoStep
            cart={cart}
            customerInfo={customerInfo}
            onCustomerInfoChange={handleCustomerInfoChange}
            emailError={emailError}
          />
        );
      case 2:
        return (
          <PaymentStep
            cart={cart}
            customerInfo={customerInfo}
            onPaymentSuccess={handlePaymentSuccess}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Container maxWidth="lg">
              <Typography variant="h3" align="center" gutterBottom sx={{ my: 4 }}>
                3 Step Shirts
              </Typography>
              <Stepper
                activeStep={paymentSuccessful ? steps.length : activeStep}
                alternativeLabel
                sx={{ mb: 4 }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box sx={{ display: 'flex', gap: 3 }}>
                <Box sx={{ flex: 7 }}>
                  <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h5" gutterBottom>
                      {steps[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                      {activeStep !== 2 || !paymentSuccessful ? (
                        <Button disabled={activeStep === 0} onClick={handleBack}>
                          Back
                        </Button>
                      ) : (
                        <div />
                      )}
                      {activeStep !== steps.length - 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={
                            cart.length === 0 ||
                            (activeStep === 1 && !validateEmail(customerInfo.email))
                          }
                        >
                          Next
                        </Button>
                      )}
                    </Box>
                  </Paper>
                </Box>
                <Box sx={{ flex: 3 }}>
                  <OrderSummary
                    cart={cart}
                    customerInfo={customerInfo}
                    updateQuantity={handleUpdateQuantity}
                    removeItem={handleRemoveItem}
                    mutable={cartMutable}
                  />
                </Box>
              </Box>
            </Container>
          }
        />
        <Route path="/order/:orderId" element={<OrderDetailsPage />} />
      </Routes>
    </Router>
  );
}

export default App;

