import { drawRulers } from './drawRulers';

export const drawTShirt = (
  canvas,
  color,
  uploadedImage,
  imageRef,
  imagePosition,
  imageSize,
  isDragging,
  isResizing,
  onCompositeImageCreated,
  side
) => {
  const ctx = canvas.getContext('2d');
  const textureImage = new Image();
  const maskImage = new Image();
  let imagesLoaded = 0;

  const canvasSize = canvas.width;
  const gridSize = canvasSize / 3;
  const zoomLevel = 1.5;
  const verticalShift = 70;

  function draw() {
    if (imagesLoaded === 2) {
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = canvasSize;
      tempCanvas.height = canvasSize;
      const tempCtx = tempCanvas.getContext('2d');

      tempCtx.fillStyle = color;
      tempCtx.fillRect(0, 0, canvasSize, canvasSize);
      tempCtx.drawImage(maskImage, 0, 0, canvasSize, canvasSize);
      tempCtx.globalCompositeOperation = 'source-in';

      const scaledSize = canvasSize * zoomLevel;
      const offsetX = (canvasSize - scaledSize) / 2;
      const offsetY = ((canvasSize - scaledSize) / 2) - verticalShift;

      ctx.clearRect(0, 0, canvasSize, canvasSize);
      ctx.drawImage(tempCanvas, offsetX, offsetY, scaledSize, scaledSize);
      ctx.drawImage(textureImage, offsetX, offsetY, scaledSize, scaledSize);

      if (uploadedImage && imageRef.current) {
        const centerX = gridSize + (gridSize - imageSize.width) / 2;
        const centerY = gridSize + (gridSize - imageSize.height) / 2;
        ctx.drawImage(
          imageRef.current,
          centerX + imagePosition.x,
          centerY + imagePosition.y,
          imageSize.width,
          imageSize.height
        );
      }

      // Draw guidelines if dragging or resizing
      if (isDragging || isResizing) {
        ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)';
        ctx.lineWidth = 2;
        ctx.setLineDash([5, 5]);
        ctx.strokeRect(gridSize, gridSize, gridSize, gridSize);
        ctx.setLineDash([]);
      }

      // Draw rulers
      drawRulers(ctx, isResizing, gridSize, imageSize, imagePosition);

      const compositeImage = canvas.toDataURL('image/png');
      onCompositeImageCreated(compositeImage, uploadedImage);
    }
  }

  textureImage.onload = maskImage.onload = function() {
    imagesLoaded++;
    if (imagesLoaded === 2) draw();
  };

  if (side === "front") {
    textureImage.src = '/tshirt_texture.png';
    maskImage.src = '/tshirt_mask.png';
  } else {
    textureImage.src = '/tshirt_back_texture.png';
    maskImage.src = '/tshirt_back_mask.png';
  }

  return {
    textureImage,
    maskImage,
  };
};
