import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  InputBase,
  Paper
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import TShirt from './TShirt';

const colorMap = {
  'white': '#ffffff',
  'black': '#000000',
};

const ColorButton = ({ colorName, selected, onClick }) => (
  <Button
    sx={{
      width: 30,
      height: 30,
      minWidth: 30,
      backgroundColor: colorMap[colorName],
      border: selected ? '2px solid black' : '1px solid #ccc',
      '&:hover': { backgroundColor: colorMap[colorName] },
    }}
    onClick={onClick}
  />
);

function CustomizationStep({ customizer, setCustomizer, handleAddToCart }) {
  const [activeSide, setActiveSide] = useState('front');
  const [uploadedImages, setUploadedImages] = useState({ front: null, back: null });
  const [compositeImages, setCompositeImages] = useState({ front: null, back: null });
  const [isImageWithinBounds, setIsImageWithinBounds] = useState({ front: true, back: true });

  useEffect(() => {
    if (compositeImages.front) {
      localStorage.setItem('savedTShirtDesignFront', compositeImages.front);
    }
    if (compositeImages.back) {
      localStorage.setItem('savedTShirtDesignBack', compositeImages.back);
    }
  }, [compositeImages]);

  const handleColorChange = (colorName) => {
    setCustomizer((prev) => {
      const newCustomizer = prev.clone();
      newCustomizer.color = colorName;
      return newCustomizer;
    });
  };

  const handleSizeChange = (size) => {
    setCustomizer((prev) => {
      const newCustomizer = prev.clone();
      newCustomizer.size = size;
      return newCustomizer;
    });
  };

  const handleQuantityChange = (change) => {
    setCustomizer((prev) => {
      const newCustomizer = prev.clone();
      newCustomizer.quantity = Math.max(1, prev.quantity + change);
      return newCustomizer;
    });
  };

  const handleUpdateImageSize = (imageLength, imageWidth, side) => {
    setCustomizer((prev) => {
      const newCustomizer = prev.clone();
      if (side === 'front') {
        newCustomizer.imageLengthFront = imageLength;
        newCustomizer.imageWidthFront = imageWidth;
      } else {
        newCustomizer.imageLengthBack = imageLength;
        newCustomizer.imageWidthBack = imageWidth;
      }
      return newCustomizer;
    });
  };

  const handleCompositeImageCreated = (compositeImage, uploadedImage) => {
    setCompositeImages((prev) => ({
      ...prev,
      [activeSide]: compositeImage,
    }));
    customizer.setCompositeImage(compositeImage, activeSide);
    customizer.setImage(uploadedImage, activeSide);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedImages((prev) => ({
          ...prev,
          [activeSide]: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageBoundsChange = (isWithinBounds, side) => {
    setIsImageWithinBounds((prev) => ({
      ...prev,
      [side]: isWithinBounds,
    }));
  };

  const isCurrentImageWithinBounds = isImageWithinBounds[activeSide];
  const uploadedImage = uploadedImages[activeSide];

  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <TShirt
          side={activeSide}
          color={customizer.color}
          uploadedImage={uploadedImages[activeSide]}
          onCompositeImageCreated={handleCompositeImageCreated}
          onImageBoundsChange={handleImageBoundsChange}
          handleUpdateImageSize={handleUpdateImageSize}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>Side</Typography>
        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          {['front', 'back'].map((side) => (
            <Button
              key={side}
              variant={activeSide === side ? 'contained' : 'outlined'}
              onClick={() => setActiveSide(side)}
            >
              {side.charAt(0).toUpperCase() + side.slice(1)}
            </Button>
          ))}
        </Box>

        <Typography variant="h6" gutterBottom>Color</Typography>
        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          {Object.keys(colorMap).map((colorName) => (
            <ColorButton
              key={colorName}
              colorName={colorName}
              selected={customizer.color === colorName}
              onClick={() => handleColorChange(colorName)}
            />
          ))}
        </Box>

        <Typography variant="h6" gutterBottom>Size</Typography>
        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          {['S', 'M', 'L'].map((size) => (
            <Button
              key={size}
              variant={customizer.size === size ? 'contained' : 'outlined'}
              onClick={() => handleSizeChange(size)}
            >
              {size}
            </Button>
          ))}
        </Box>

        <Typography variant="h6" gutterBottom>Quantity</Typography>
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 120, border: '1px solid #e0e0e0' }}
        >
          <Button sx={{ p: '4px', minWidth: '32px' }} aria-label="decrease" onClick={() => handleQuantityChange(-1)}>
            <Remove fontSize="small" />
          </Button>
          <InputBase
            sx={{ flex: 1, textAlign: 'center', input: { padding: '2px', fontSize: '0.875rem' } }}
            value={customizer.quantity}
            inputProps={{ 'aria-label': 'quantity', style: { textAlign: 'center' } }}
            readOnly
          />
          <Button sx={{ p: '4px', minWidth: '32px' }} aria-label="increase" onClick={() => handleQuantityChange(1)}>
            <Add fontSize="small" />
          </Button>
        </Paper>

        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ mt: 2 }}
        >
          {uploadedImage ? 'Change Image' : 'Upload Image'}
          <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
        </Button>

        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddToCart}
            fullWidth
            disabled={
              (!uploadedImages.front && !uploadedImages.back) ||
              !isImageWithinBounds.front ||
              !isImageWithinBounds.back
            }
          >
            {(!uploadedImages.front && !uploadedImages.back)
              ? 'Upload images for both sides'
              : (!isImageWithinBounds.front || !isImageWithinBounds.back)
                ? 'Adjust images within bounds'
                : 'Add to Cart'}
          </Button>
        </Box>
        {(!isImageWithinBounds.front || !isImageWithinBounds.back) && (
          <Typography color="error" sx={{ mt: 1, textAlign: 'center' }}>
            One or more images are out of bounds. Please adjust.
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CustomizationStep;

